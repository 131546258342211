<div *ngIf="inputType == 'image' || inputType == 'floor'">
  <!-- <div class="col-lg-12 px-0 listing_carousel" *ngIf="alreadyUploadedImages.length">
    <div class="info_carousal">
      <div id="demo" class="carousel slide">
        <app-image-gallery> </app-image-gallery>
      </div>
    </div>
  </div> -->
  <div
    class="dropzone center"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
  >
    <label for="file-upload" class="upload_btn">
      <img src="../../../../assets/images/add.png" width="15px" />
      Upload Photos
    </label>
    <input id="file-upload" type="file" multiple (change)="selectFiles($event)" />
  </div>
  <mat-error>{{ message }}</mat-error>
  <div class="clearfix">&nbsp;</div>
  <h3><b class="upload-img">Uploaded Images</b> (Drag to reorder photos)</h3>

  <div
    class=""
    [ngStyle]="{ 'margin-top': alreadyUploadedImages.length == 0 ? '0' : '36px' }"
    cdkDropList
    [cdkDropListData]="alreadyUploadedImages"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="selected_images" *ngIf="inputType == 'image'">
      <!-- </div> -->
      <div class="" *ngFor="let previewsItem of alreadyUploadedImages; let i = index" cdkDrag>
        <div class="image_wrap">
          <div></div>
          <button
            class="delete_icon"
            *ngIf="!previewsItem.loading"
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
          >
            <i class="fa fa-ellipsis-v"></i>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="!previewsItem.loading" (click)="setDefaultImageModal(previewsItem.url)">
              <mat-icon class="pr-3">perm_media</mat-icon>Set as default image
            </button>
            <button mat-menu-item *ngIf="!previewsItem.loading" (click)="deleteImages(i)">
              <mat-icon class="pr-3">delete</mat-icon>Delete
            </button>
          </mat-menu>
          <button class="drag_icon">
            {{ i + 1 }}
          </button>
          <div
            class="image_container"
            [ngClass]="{
              highlight: uploadedFilesResult.defaultPropertyImage === previewsItem.url,
              default: uploadedFilesResult.defaultPropertyImage !== previewsItem.url
            }"
            style="position: relative"
          >
            <img class="preview_image" [src]="previewsItem.url" alt="" (click)="openGallery(i)" />

            <div *ngIf="previewsItem.loading" class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
    <app-image-gallery
      *ngIf="isGalleryOpen"
      [selectedIndex]="currentIndex"
      (close)="closeGallery()"
      [isImagesFullView]="true"
    ></app-image-gallery>
    <ng-template #setDefaultImageDialog>
      <div>
        <h3 mat-dialog-title>Are you sure you want to set this image as the profile image for the property?</h3>
        <div mat-dialog-content>
          <div class="row">
            <div class="col-md-12">
              <div class="w-100 text-right mt-3">
                <button type="button" mat-stroked-button color="primary" (click)="closeModal()">No</button>
                <button type="submit" (click)="setDefaultImage()" mat-flat-button class="ml-2" color="primary">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="selected_images" *ngIf="inputType == 'floor'">
      <div class="image_wrap" *ngFor="let previewsItem of alreadyUploadedFloorImages; let i = index" cdkDrag>
        <button class="delete_icon" *ngIf="!previewsItem.loading" (click)="deleteImages(i)">
          <img src="../../../assets/images/delete_icon.svg" alt="" />
        </button>
        <button class="drag_icon">
          {{ i + 1 }}
        </button>
        <div class="image_container" style="position: relative">
          <img class="preview_image" [src]="previewsItem.url" alt="" />
          <div *ngIf="previewsItem.loading" class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Document Uploads  -->
<div *ngIf="inputType == 'document'">
  <div class="dropzone center">
    <label for="file-upload pr-3" class="upload_btn" (click)="openModal()">
      <img src="../../../../assets/images/add.png" width="15px" />
      Upload Document
    </label>
  </div>
  <div class="clearfix"></div>
  <ng-template #documentUploadDialog>
    <div class="confirmDialogWrap">
      <button class="close" mat-button mat-dialog-close type="button" [disabled]="" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>

      <div>
        <h3 mat-dialog-title>Upload Document</h3>

        <div mat-dialog-content>
          <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="w-100">
                  <input matInput id="name" formControlName="name" type="text" placeholder="Document Name" />
                  <div *ngIf="myForm.get('name').invalid && myForm.get('name').touched">
                    <small *ngIf="myForm.get('name').errors?.required">Name is required.</small>
                  </div>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <mat-label><b>Upload Document:</b></mat-label>
                <br />
                <input class="mt-2" type="file" (change)="onFileSelected($event)" />
                <div *ngIf="fileErrorMessage">
                  <mat-error>{{ fileErrorMessage }}</mat-error>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="w-100 text-right mt-3">
                  <button type="button" mat-stroked-button color="primary" (click)="closeModal()">Close</button>
                  <button
                    type="submit"
                    mat-flat-button
                    class="ml-2"
                    color="primary"
                    [disabled]="myForm.invalid || !selectedFile"
                    [pmx-progress-button]="inProgressDocumentUpload"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="image_wrap mt-3">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Document Name</th>
          <th scope="col">Date</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let previewsItem of uploadedArrayResult?.document; let i = index">
          <td>{{ previewsItem.name }}</td>
          <td>{{ previewsItem.createdDate | simpleDate }}</td>
          <td class="icon">
            <i class="fa fa-trash" (click)="deleteImages(i)"></i>
            <i class="fa fa-download" (click)="downloadFile(previewsItem.Location, 'testing')" aria-hidden="true"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
