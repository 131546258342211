<!-- <div id="inline-gallery-container" class="inline-gallery-container" (click)="leadScoreUpdate()"></div> -->

<div *ngIf="isLoading" class="loader">Loading Images...</div>
<ngx-gallery
  class="ngx-gallery"
  *ngIf="!isLoading"
  [options]="galleryOptions"
  [images]="galleryImagesList"
  #gallery
></ngx-gallery>
