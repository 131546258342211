import { Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MyHomeState } from 'src/app/my-home-page/state/my-home.state';
import { ArticleService } from '../../service/article.service';

@Component({
  selector: 'app-article-sliding-sheet',
  templateUrl: './article-sliding-sheet.component.html',
  styleUrls: ['./article-sliding-sheet.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ArticleSlidingSheetComponent {

  @ViewChild('articleContainer') protected articleContainer: ElementRef;
  @ViewChild('sidenav') protected sidenav: MatSidenav;

  isArticleLoadingInProgress: boolean = true;
  articleTitle: string = null;
  articleContent: SafeHtml = null;

  constructor(
    private _renderer: Renderer2,
    private sanitizer: DomSanitizer,
    public articleService: ArticleService,
    public myHomeState: MyHomeState,
  ) { }

  ngOnInit(): void {
    this.articleService.getArticle(this.myHomeState.isArticleSheetOpenedValue).subscribe(({ isLoading, data }) => {
      this.isArticleLoadingInProgress = isLoading;
      if (!data) return;

      this.articleTitle = data?.title || '';
      this.articleContent = this.sanitizer.bypassSecurityTrustHtml(data?.content);
      // Use the sanitized content directly in the template
      this._renderer.setProperty(this.articleContainer.nativeElement, 'innerHTML', data?.content);
    });
  }


  public close(): void {
    this.myHomeState.isArticleSheetOpenedValue = null;
    this.sidenav.close();
  }
}
