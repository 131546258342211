// google-maps.service.ts
import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: 'AIzaSyCWeYlLim9v-vpHgWlscf5l-E8EVo_RuQs', // Replace with your API key
      libraries: ['marker'], // Load the marker library
    });
  }

  load() {
    return this.loader.load();
  }
}
