import 'zone.js/plugins/zone-error';
export const environment = {
  env: 'dev',
  production: false,
  baseUrl: 'dev.prospektr.ai',
  prospektrBackendBaseURL: 'https://dev-prospectorapi.propmix.io/',
  mediaUrl: 'https://media.propmix.io/mlslite/media/GetImageURL?fromApp=',
  trialcompanyCode: 'WX84F6E',
  defaultSignUp: 'https://dev.prospektr.ai/signup/',
  company: {
    tabrasa: {
      name: 'Tabrasa',
      companyCode: 'QKHPUNX',
    },
    prospektr: {
      name: 'Prospektr',
      companyCode: 'WX84F6E',
    },
  },
  s3Upload: {
    project: 'PROSPECTOR',
    env: 'DEV',
    type: 'base64',
  },
  defaultCompany: 'app',
  propmixBaseURL: 'https://api.propmix.io/',
  listingImageBaseUrl: 'https://qa-media.propmix.io/mktlite/media/image',
  weatherTemperatureApiKey: 'eb0152fe0e2b4386b9762939242310',
  weatherTemperatureApiUrl: 'http://api.weatherapi.com/v1/current.json',
};
