import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizeService } from './authorize.service';

@Directive({
  selector: '[pmxAuthorize]',
})
export class AuthorizeDirective implements OnInit {
  constructor(private authorizeService: AuthorizeService, private elementRef: ElementRef) { }

  @Input('pmxAuthorize') feature: string;

  ngOnInit() {
    if (!this.authorizeService.canView(this.feature)) {
      this.elementRef.nativeElement.remove();
    }
  }
}
