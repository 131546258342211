<mat-sidenav-container>
  <mat-sidenav
    class="main row create_campaign"
    #sidenav
    mode="over"
    position="end"
    [opened]="myHomeState.isArticleSheetOpenedValue !== null"
    disableClose
  >
    <app-progress-indicator *ngIf="isArticleLoadingInProgress"></app-progress-indicator>

    <div
      class="sliding-sheet-header"
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        z-index: 1;
        top: 0px;
        background-color: rgb(238, 238, 238) !important;
        font-family: var(--global-font-heading);
        font-size: 17px;
        font-weight: 700;
      "
    >
      <h4 style="margin: 0 0 0 1.5em; display: inline-block">{{ articleTitle }}</h4>
      <button
        type="button"
        (click)="close()"
        style="
          height: 50px;
          width: 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: rgb(102, 102, 102);
          color: rgb(255, 255, 255);
          font-size: 25px;
          cursor: pointer;
          outline: none;
          border-width: initial;
          border-style: none;
          border-color: initial;
          border-image: initial;
        "
      >
        <mat-icon>x</mat-icon>
      </button>
    </div>

    <div class="sliding-sheet-body px-5" [innerHTML]="articleContent"></div>

    <div class="not-found" *ngIf="!isArticleLoadingInProgress && !articleContent">
      <img class="img-fluid" width="60" src="~/../assets/images/notFound.svg" />
      <h4>Article not found!</h4>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
