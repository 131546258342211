import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { ProspektrApi } from 'src/app/_shared/api/prospektr.api';
import { PublicRecordApi } from 'src/app/_shared/api/public-record.api';
import { AgentReportGetParam } from 'src/app/_shared/interface/agent-analytics.interface';
import { BackendResponse } from 'src/app/_shared/interface/common.interface';
import { UserState } from 'src/app/_shared/state/user.state';
import { ClientDashboardState } from '../state/client-dashboard.state';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardService {
  constructor(
    private snackBar: MatSnackBar,
    private prospektrApi: ProspektrApi,
    private clientDashboardState: ClientDashboardState,
    private appState: AppState,
    private publicRecordApi: PublicRecordApi,
    private userState: UserState
  ) {}

  setAsDefaultPartner(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .setAsDefaultPartner(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.status === 'success') {
              this.openSnackBar(res.message, 'snackbar-success');
            } else {
              this.openSnackBar('Some error occurred while saving default branding partner', 'snackbar-error');
            }
          },
          (err) => {
            let message =
              err && err.error && err.error.message
                ? err.error.message
                : `'Some error occurred while saving default branding partner'`;
            this.openSnackBar(message, 'snackbar-error');
          }
        );
    });
  }

  fetchActivePartners(params?): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchActivePartners(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.clientDashboardState.activePartnerListValue = res.data;
              this.clientDashboardState.activePartnersCountValue = res.totalCount ? res.totalCount : 0;
            } else {
              this.clientDashboardState.activePartnerListValue = [];
              this.clientDashboardState.activePartnersCountValue = 0;
            }
          },
          (err) => {
            this.clientDashboardState.activePartnerListValue = [];
            this.clientDashboardState.activePartnersCountValue = 0;
          }
        );
    });
  }

  fetchAgentsAndLoanOfficers(params?) {
    let response = { data: null, totalCount: 0 };
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchAgentsAndLoanOfficers(params)
        .pipe(
          finalize(() => {
            observer.next({ data: response, isLoading: false });
            observer.complete();
          })
        )
        .subscribe((res: any) => {
          response = { data: res.data, totalCount: res.totalCount };
          this.clientDashboardState.clientUserListValue = response;
        });
    });
  }

  fetchLoanOfficers(params?) {
    if (
      this.userState.userDetails?.roles.includes('companyManager') ||
      this.userState.userDetails?.roles.includes('companyEmployee')
    ) {
      let response = { data: null, totalCount: 0 };
      return new Observable((observer) => {
        observer.next({ isLoading: true });
        this.prospektrApi
          .fetchAgentsAndLoanOfficers(params)
          .pipe(
            finalize(() => {
              observer.next({ data: response, isLoading: false });
              observer.complete();
            })
          )
          .subscribe((res: any) => {
            response = { data: res.data, totalCount: res.totalCount };
            this.clientDashboardState.loanOfficerListValue = response;
          });
      });
    }

    if (this.userState.userDetails?.roles.includes('agent')) {
      if (this.appState.companyInfoValue.alias == 'valuetest') {
        let response = { data: null, totalCount: 0 };

        return new Observable((observer) => {
          observer.next({ isLoading: true });
          this.prospektrApi.getConnectedLoanOfficers().subscribe((res: any) => {
            if (res && res.userData) {
              let connectedLoanOfficers = res.userData.filter((item) => item.isCardAdded === true);
              response = {
                data: connectedLoanOfficers,
                totalCount: connectedLoanOfficers && connectedLoanOfficers.length ? connectedLoanOfficers.length : 0,
              };
              this.clientDashboardState.loanOfficerListValue = response;
            } else {
              this.clientDashboardState.loanOfficerListValue = null;
            }
          });
        });
      } else {
        let response = { data: null, totalCount: 0 };
        return new Observable((observer) => {
          observer.next({ isLoading: true });
          this.prospektrApi
            .fetchActivePartners(params ? params : {})
            .pipe(
              finalize(() => {
                observer.next({ data: response, isLoading: false });
                observer.complete();
              })
            )
            .subscribe((res: any) => {
              response = { data: res.data, totalCount: res.totalCount };
              this.clientDashboardState.loanOfficerListValue = response;
            });
        });
      }
    }
  }

  fetchAgents(params?) {
    if (
      this.userState.userDetails?.roles.includes('companyManager') ||
      this.userState.userDetails?.roles.includes('companyEmployee')
    ) {
      let response = { data: null, totalCount: 0 };
      return new Observable((observer) => {
        observer.next({ isLoading: true });
        this.prospektrApi
          .fetchAgentsAndLoanOfficers(params)
          .pipe(
            finalize(() => {
              observer.next({ data: response, isLoading: false });
              observer.complete();
            })
          )
          .subscribe((res: any) => {
            response = { data: res.data, totalCount: res.totalCount };
            this.clientDashboardState.agentListValue = response;
          });
      });
    }

    if (this.userState.userDetails?.roles.includes('loanOfficer')) {
      let response = { data: null, totalCount: 0 };
      return new Observable((observer) => {
        observer.next({ isLoading: true });
        this.prospektrApi
          .fetchActivePartners(params ? params : {})
          .pipe(
            finalize(() => {
              observer.next({ data: response, isLoading: false });
              observer.complete();
            })
          )
          .subscribe((res: any) => {
            response = { data: res.data, totalCount: res.totalCount };
            this.clientDashboardState.agentListValue = response;
          });
      });
    }
  }

  fetchAgentsAndLoanOfficersReassign(params?) {
    let response = { data: null, totalCount: 0 };
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchAgentsAndLoanOfficers(params)
        .pipe(
          finalize(() => {
            observer.next({ data: response, isLoading: false });
            observer.complete();
          })
        )
        .subscribe((res: any) => {
          response = { data: res.data, totalCount: res.totalCount };
          this.clientDashboardState.clientUserDetailsValue = response;
        });
    });
  }

  fetchInvestors(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchInvestors(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res && res.data && res.status === 'OK') {
              this.clientDashboardState.investorListValue = res.data;
              this.clientDashboardState.investorListCountValue = res.totalCount ? res.totalCount : 0;
              this.leadDashboardLogs();
            } else {
              this.clientDashboardState.investorListValue = [];
              this.clientDashboardState.investorListCountValue = 0;
            }
          },
          (err) => {
            this.clientDashboardState.investorListValue = [];
            this.clientDashboardState.investorListCountValue = 0;
          }
        );
    });
  }

  fetchInvestorsUnderMarketingEffectiveness(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchInvestorsUnderMarketingEffectiveness(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res && res.data && res.status === 'OK') {
              this.clientDashboardState.clientListUnderMarketingValue = res.data;
              this.clientDashboardState.clientListUnderMarketingCountValue = res.totalCount ? res.totalCount : 0;
            } else {
              this.clientDashboardState.clientListUnderMarketingValue = [];
              this.clientDashboardState.clientListUnderMarketingCountValue = 0;
            }
          },
          (err) => {
            this.clientDashboardState.clientListUnderMarketingValue = [];
            this.clientDashboardState.clientListUnderMarketingCountValue = 0;
          }
        );
    });
  }

  fetchClientMarketingEmail(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchClientDetailsUnderMarketing(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res && res.data && res.status === 'OK') {
              this.clientDashboardState.activeClientMarketingEmailDetailsValue = res.data;
              this.clientDashboardState.marketingEmailDetailsValue = res.totalCount;
            } else {
              this.clientDashboardState.activeClientMarketingEmailDetailsValue = [];
              this.clientDashboardState.marketingEmailDetailsValue = 0;
            }
          },
          (err) => {
            this.clientDashboardState.activeClientMarketingEmailDetailsValue = [];
            this.clientDashboardState.marketingEmailDetailsValue = 0;
          }
        );
    });
  }

  fetchClientMarketingProperty(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .fetchEmailDetails(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  getSavedDeals(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getSavedDeals(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.data) {
              this.clientDashboardState.savedDealDataValue = res.data;
            } else {
              this.clientDashboardState.savedDealDataValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.savedDealDataValue = [];
          }
        );
    });
  }

  getAgentId(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getAgentIdList(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.status === 'success' && res.data) {
              this.clientDashboardState.activeAgentListValue = res.data;
            } else {
              this.clientDashboardState.activeAgentListValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.activeAgentListValue = [];
          }
        );
    });
  }

  getStatusTag(leadType, leadStatus) {
    let tagList = [];
    if (leadType == 'buyer') {
      if (this.appState.companyInfoValue.buyerLeadCategory[leadStatus].isQualified) tagList.push('qualified');
      if (this.appState.companyInfoValue.buyerLeadCategory[leadStatus].isCustomer) tagList.push('customer');
      if (this.appState.companyInfoValue.buyerLeadCategory[leadStatus].isClient) tagList.push('client');
    }
    if (leadType == 'seller') {
      if (this.appState.companyInfoValue.sellerLeadCategory[leadStatus].isQualified) tagList.push('qualified');
      if (this.appState.companyInfoValue.sellerLeadCategory[leadStatus].isCustomer) tagList.push('customer');
      if (this.appState.companyInfoValue.sellerLeadCategory[leadStatus].isClient) tagList.push('client');
    }
    return tagList;
  }

  getLoggedActivities(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getLoggedActivities(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.status === 'success' && res.data) {
              this.clientDashboardState.activityLogDataValue = res.data;
            } else {
              this.clientDashboardState.activityLogDataValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.activityLogDataValue = [];
          }
        );
    });
  }

  savePropertySearchForInvestor(params) {}

  getSentMails(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getSentMails(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.status === 'OK' && res.data) {
              this.clientDashboardState.sentMailListValue = res.data;
            } else {
              this.clientDashboardState.sentMailListValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.sentMailListValue = [];
          }
        );
    });
  }

  sendMail(file, id) {
    return new Observable((observer) => {
      this.prospektrApi.sentMail(file, id).subscribe(
        (res) => {
          this.getSentMails(id)
            .pipe(
              finalize(() => {
                observer.next();
                observer.complete();
              })
            )
            .subscribe();
          observer.next(res);
          this.openSnackBar(res.message, 'snackbar-success');
        },
        (err) => {
          this.openSnackBar(
            err.error && err.error.message ? err.error.message : 'Some error occurred',
            'snackbar-error'
          );
        }
      );
    });
  }

  getPropertyDetails(params) {
    return new Observable((observer) => {
      this.publicRecordApi.getPropertyDetails(params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  inviteAppraiser(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .inviteAppraiser(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  inviteLoanOfficer(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .inviteLoanOfficer(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }
  inviteEmployee(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .inviteEmployee(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  fetchEmailTemplate(agentInfo) {
    let result;
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getEmailTemplate(agentInfo)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next({ data: res.data });
          },
          (err) => {}
        );
    });
  }

  inviteUser(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .inviteUser(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  inviteInvestor(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .inviteInvestor(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res?.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  downloadAttachment(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .downloadAttchment(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            this.openSnackBar(res?.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  public fetchAgentAnalytics(params: AgentReportGetParam): Observable<{ isLoading: boolean }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getReport({ type: 'agentAnalytics', ...params })
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.status === 'success' && res.data) {
              this.clientDashboardState.agentReportValue = res.data;
              this.clientDashboardState.agentReportTotalCountValue = res.totalRecord;
            } else if (res.status === 'error') {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (error) => {
            this.openSnackBar(
              error.error && error.error.message ? error.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  getConnectionDetails(params?) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getConnectionDetail(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.status && res.statusCode === 200 && res.data) {
              this.clientDashboardState.savedAgentConnectionDataValue = res.data;
            } else {
              this.clientDashboardState.savedDealDataValue = [];
            }
          },
          (err) => {}
        );
    });
  }

  updateAgentConnections(investorId, params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateInvestorConnection(investorId, params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            if (res && res.status == 'OK') {
              this.openSnackBar(res.message, 'snackbar-success');
            } else {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  updateLeadStatus(investorId, params): Observable<{ isLoading: boolean }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateLeadStatus(investorId, params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.openSnackBar(res.message, 'snackbar-success');
            } else {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  resendActivationMail(params): Observable<{ isLoading: boolean }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .resendActivationMail(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            this.clientDashboardState.activeClientDetailsValue['lastSignUpReminderDate'] = new Date();
            this.openSnackBar(res?.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(err?.error?.message || 'Some error occurred', 'snackbar-error');
          }
        );
    });
  }

  exportMyClientsToCSV(params?): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .exportMyClientsToCSV(params ? params : {})
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
          },
          (err) => {
            observer.next(err.error);
          }
        );
    });
  }

  exportUserListToCSV(params?) {
    return new Observable((observer) => {
      this.prospektrApi.exportUserListToCSV(params ? params : {}).subscribe(
        (res) => {
          if (res.status && res.status == 'OK' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to get userList', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  leadDashboardLogs() {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .leadLogCountDetail()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              this.clientDashboardState.logCountLeadDashboardValue = res.data;
            } else {
              this.clientDashboardState.logCountLeadDashboardValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.logCountLeadDashboardValue = [];
          }
        );
    });
  }

  getUsersNotes(investorId) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getUsersNote(investorId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res.data) {
              this.clientDashboardState.userNoteListValue = res.data;
            } else {
              this.clientDashboardState.userNoteListValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.userNoteListValue = [];
          }
        );
    });
  }

  getMyHomeProperties(investorId) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getMyHomeProperties(investorId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res.data) {
              this.clientDashboardState.myPropertyListValue = res.data;
            } else {
              this.clientDashboardState.myPropertyListValue = [];
            }
          },
          (err) => {
            this.clientDashboardState.myPropertyListValue = [];
            console.log(err, 'err');
          }
        );
    });
  }

  addNotesDetails(noteId, paramsObj) {
    let params = {
      note: paramsObj.note,
    };
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .addUserNote(noteId, params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            if (res) {
              let noteUserList: any = this.clientDashboardState.userNoteListValue;
              noteUserList.push(res.data);
              const newArr = noteUserList.sort(
                (a, b) => new Date(b.updatedDate).valueOf() - new Date(a.updatedDate).valueOf()
              );
              this.clientDashboardState.userNoteListValue = noteUserList;
              this.openSnackBar(res.message, 'snackbar-success');
            } else {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  updateNotesDetails(noteId, paramsObj) {
    let params = {
      note: paramsObj.note,
    };
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateUserNote(noteId, params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            if (res) {
              let noteUserList: any = this.clientDashboardState.userNoteListValue;
              noteUserList = noteUserList.filter((notes, index) => {
                if (notes._id == paramsObj._id) {
                  notes.note = paramsObj.note;
                  noteUserList.push(notes);
                }
                return noteUserList;
              });
              this.openSnackBar(res.message, 'snackbar-success');
              this.clientDashboardState.userNoteListValue = noteUserList;
            } else {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  deleteUserNotes(noteId) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .deleteUserNote(noteId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            if (res) {
              let noteUserList: any = this.clientDashboardState.userNoteListValue;
              noteUserList = noteUserList.filter((notes, index) => {
                return notes._id != noteId;
              });
              this.clientDashboardState.userNoteListValue = noteUserList;
              this.openSnackBar(res.message, 'snackbar-success');
            } else {
              this.openSnackBar(res.message, 'snackbar-error');
            }
          },
          (err) => {
            this.openSnackBar(
              err.error && err.error.message ? err.error.message : 'Some error occurred',
              'snackbar-error'
            );
          }
        );
    });
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}
